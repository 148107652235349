import React, { useState } from "react"
import axios from "axios"
import qs from "qs"
import Cookies from "js-cookie"
import {
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap"

import { isProduction, isBrowser } from "../../utilities/environmentUtilities"

const GetStartedForm = () => {
  const params =
    isBrowser() &&
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

  const [form, setForm] = useState({
    title: params.title || "",
    first_name: params.first_name || "",
    last_name: params.last_name || "",
    email: params.email || "",
    phone: params.phone || "",
    content: "",
    referral_code: params.referral_code || "",
    specialty: params.specialty || "",
    practice_size: params.practice_size || "",
    role: params.role || "",
  })
  const [formErrors, setFormErrors] = useState({})

  const validateEmail = string =>
    String(string)
      .toLowerCase()
      .match(/^\S+@\S+\.\S+$/)

  const validatePhone = string => string.match(/\d{11,20}/)

  const validateForm = () => {
    let newFormErrors = {}

    if (form.title.length < 2) {
      newFormErrors.title = "Please select a title."
    }

    if (form.first_name.length < 2) {
      newFormErrors.first_name = "Please add a first name."
    }

    if (form.last_name.length < 2) {
      newFormErrors.last_name = "Please add a last name."
    }

    if (!form.role.length) {
      newFormErrors.role = "Please select a role."
    }

    if (!form.specialty.length) {
      newFormErrors.specialty = "Please select a specialty."
    }

    if (!form.practice_size.length) {
      newFormErrors.practice_size = "Please select a number."
    }

    if (!validatePhone(form.phone)) {
      newFormErrors.phone =
        "Please add a phone number of between 11 and 20 digits."
    }

    if (!validateEmail(form.email)) {
      newFormErrors.email = "Please add a valid email address."
    }

    if (form.content.length < 10) {
      newFormErrors.content =
        "Please add a short message of 10 characters or more."
    }

    return newFormErrors
  }

  const handleSubmit = async () => {
    let newFormErrors = validateForm()
    setFormErrors(newFormErrors)

    if (Object.keys(newFormErrors).length) {
      return
    }

    if (Cookies.get("isPatient")) {
      alert(
        "Thank you for submitting the form. Please contact your practice to resolve your query."
      )
      return
    }

    if (form.role === "Patient") {
      // Set the isPatient cookie to expire in 2 weeks
      Cookies.set("isPatient", "true", { expires: 14 })

      alert(
        "Thank you for submitting the form. Please contact your practice to resolve your query."
      )
      return
    }

    let body = {
      customer_referral: {
        title: form.title,
        first_name: form.first_name,
        last_name: form.last_name,
        phone: form.phone,
        email: form.email,

        content: form.content,
        practice_size: form.practice_size,
        referral_code: form.referral_code,
        role: form.role,
        specialty: form.specialty,
        lead_source: "carebit.co",
      },
    }

    let endpoint = isProduction
      ? "https://carebit.carebit.co"
      : "https://santis.dev-carebit.co:3000"

    try {
      await axios.post(`${endpoint}/api/v1/customer_referrals`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      window.open("https://carebit.youcanbook.me/", "_blank")
      alert(
        "Thank you for submitting the form - we will be in touch soon. If you prefer, you can book a demo now in the browser tab that just opened."
      )
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = event => {
    let updatedForm = Object.assign({}, form)
    updatedForm[event.target.name] = event.target.value
    setForm(updatedForm)
  }

  return (
    <div className="u-textLeft">
      <div>
        <h3 className="u-marginBottom40">
          Ready to start automating your practice? Please contact us for a
          demonstration.
        </h3>

        <Row>
          <Col lg={3} md={12}>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                value={form.title}
                onChange={handleChange}
                type="select"
                name="title"
                id="title"
                invalid={!!formErrors.title}
              >
                {[
                  { id: "", name: "", rank: -1 },
                  { id: "Dr", name: "Dr", rank: 0 },
                  { id: "Miss", name: "Miss", rank: 1 },
                  { id: "Mr", name: "Mr", rank: 2 },
                  { id: "Mrs", name: "Mrs", rank: 3 },
                  { id: "Ms", name: "Ms", rank: 4 },
                  { id: "Professor", name: "Professor", rank: 5 },
                ].map(option => (
                  <option key={option.name} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.title}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={3} md={12}>
            <FormGroup>
              <Label for="first_name">First name</Label>
              <Input
                value={form.first_name}
                onChange={handleChange}
                invalid={!!formErrors.first_name}
                type="text"
                name="first_name"
                id="first_name"
              />
              <FormFeedback>{formErrors.first_name}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={3} md={12}>
            <FormGroup>
              <Label for="last_name">Last name</Label>
              <Input
                value={form.last_name}
                onChange={handleChange}
                invalid={!!formErrors.last_name}
                type="text"
                name="last_name"
                id="last_name"
              />
              <FormFeedback>{formErrors.last_name}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={3} md={12}>
            <FormGroup>
              <Label for="specialty">Specialty</Label>
              <Input
                value={form.specialty}
                onChange={handleChange}
                type="select"
                name="specialty"
                id="specialty"
                invalid={!!formErrors.specialty}
              >
                {[
                  {
                    id: "",
                    name: "",
                    rank: -1,
                  },
                  {
                    id: "Accident and emergency medicine",
                    name: "Accident and emergency medicine",
                    rank: 0,
                  },
                  {
                    id: "Aesthetic and cosmetic",
                    name: "Aesthetic and cosmetic",
                    rank: 1,
                  },
                  {
                    id: "Allergy",
                    name: "Allergy",
                    rank: 2,
                  },
                  {
                    id: "Anaesthetics",
                    name: "Anaesthetics",
                    rank: 3,
                  },
                  {
                    id: "Cardiology",
                    name: "Cardiology",
                    rank: 4,
                  },
                  {
                    id: "Child psychiatry",
                    name: "Child psychiatry",
                    rank: 5,
                  },
                  {
                    id: "Clinical biology",
                    name: "Clinical biology",
                    rank: 6,
                  },
                  {
                    id: "Clinical chemistry",
                    name: "Clinical chemistry",
                    rank: 7,
                  },
                  {
                    id: "Clinical microbiology",
                    name: "Clinical microbiology",
                    rank: 8,
                  },
                  {
                    id: "Clinical neurophysiology",
                    name: "Clinical neurophysiology",
                    rank: 9,
                  },
                  {
                    id: "Colorectal surgery",
                    name: "Colorectal surgery",
                    rank: 10,
                  },
                  {
                    id: "Craniofacial surgery",
                    name: "Craniofacial surgery",
                    rank: 11,
                  },
                  {
                    id: "Dentistry",
                    name: "Dentistry",
                    rank: 12,
                  },
                  {
                    id: "Dermatology",
                    name: "Dermatology",
                    rank: 13,
                  },
                  {
                    id: "Dietetics",
                    name: "Dietetics",
                    rank: 14,
                  },
                  {
                    id: "Endocrinology",
                    name: "Endocrinology",
                    rank: 15,
                  },
                  {
                    id: "Family and general medicine",
                    name: "Family and general medicine",
                    rank: 16,
                  },
                  {
                    id: "Gastroenterology",
                    name: "Gastroenterology",
                    rank: 17,
                  },
                  {
                    id: "General practice",
                    name: "General practice",
                    rank: 18,
                  },
                  {
                    id: "General surgery",
                    name: "General surgery",
                    rank: 19,
                  },
                  {
                    id: "Genitourinary medicine",
                    name: "Genitourinary medicine",
                    rank: 20,
                  },
                  {
                    id: "Geriatrics",
                    name: "Geriatrics",
                    rank: 21,
                  },
                  {
                    id: "Haematology",
                    name: "Haematology",
                    rank: 22,
                  },
                  {
                    id: "Immunology",
                    name: "Immunology",
                    rank: 23,
                  },
                  {
                    id: "Infectious diseases",
                    name: "Infectious diseases",
                    rank: 24,
                  },
                  {
                    id: "Internal medicine",
                    name: "Internal medicine",
                    rank: 25,
                  },
                  {
                    id: "Laboratory medicine",
                    name: "Laboratory medicine",
                    rank: 26,
                  },
                  {
                    id: "Nephrology",
                    name: "Nephrology",
                    rank: 27,
                  },
                  {
                    id: "Neuropsychiatry",
                    name: "Neuropsychiatry",
                    rank: 28,
                  },
                  {
                    id: "Neurology",
                    name: "Neurology",
                    rank: 29,
                  },
                  {
                    id: "Neurosurgery",
                    name: "Neurosurgery",
                    rank: 30,
                  },
                  {
                    id: "Nuclear medicine",
                    name: "Nuclear medicine",
                    rank: 31,
                  },
                  {
                    id: "Nursing",
                    name: "Nursing",
                    rank: 32,
                  },
                  {
                    id: "Obstetrics and gynaecology",
                    name: "Obstetrics and gynaecology",
                    rank: 33,
                  },
                  {
                    id: "Occupational medicine",
                    name: "Occupational medicine",
                    rank: 34,
                  },
                  {
                    id: "Oncology",
                    name: "Oncology",
                    rank: 35,
                  },
                  {
                    id: "Ophthalmology",
                    name: "Ophthalmology",
                    rank: 36,
                  },
                  {
                    id: "Oral and maxillofacial surgery",
                    name: "Oral and maxillofacial surgery",
                    rank: 37,
                  },
                  {
                    id: "Orthodontics",
                    name: "Orthodontics",
                    rank: 38,
                  },
                  {
                    id: "Orthopaedics",
                    name: "Orthopaedics",
                    rank: 39,
                  },
                  {
                    id: "Other",
                    name: "Other",
                    rank: 40,
                  },
                  {
                    id: "Otorhinolaryngology (ENT)",
                    name: "Otorhinolaryngology (ENT)",
                    rank: 41,
                  },
                  {
                    id: "Paediatric surgery",
                    name: "Paediatric surgery",
                    rank: 42,
                  },
                  {
                    id: "Paediatrics",
                    name: "Paediatrics",
                    rank: 43,
                  },
                  {
                    id: "Pain medicine",
                    name: "Pain medicine",
                    rank: 44,
                  },
                  {
                    id: "Pathology",
                    name: "Pathology",
                    rank: 45,
                  },
                  {
                    id: "Pharmacology",
                    name: "Pharmacology",
                    rank: 46,
                  },
                  {
                    id: "Physical medicine and rehabilitation",
                    name: "Physical medicine and rehabilitation",
                    rank: 47,
                  },
                  {
                    id: "Plastic surgery",
                    name: "Plastic surgery",
                    rank: 48,
                  },
                  {
                    id: "Podiatric surgery",
                    name: "Podiatric surgery",
                    rank: 49,
                  },
                  {
                    id: "Preventive medicine",
                    name: "Preventive medicine",
                    rank: 50,
                  },
                  {
                    id: "Psychiatry",
                    name: "Psychiatry",
                    rank: 51,
                  },
                  {
                    id: "Psychology",
                    name: "Psychology",
                    rank: 52,
                  },
                  {
                    id: "Public health",
                    name: "Public health",
                    rank: 53,
                  },
                  {
                    id: "Radiation oncology",
                    name: "Radiation oncology",
                    rank: 54,
                  },
                  {
                    id: "Radiology",
                    name: "Radiology",
                    rank: 55,
                  },
                  {
                    id: "Respiratory medicine",
                    name: "Respiratory medicine",
                    rank: 56,
                  },
                  {
                    id: "Rheumatology",
                    name: "Rheumatology",
                    rank: 57,
                  },
                  {
                    id: "Sleep medicine",
                    name: "Sleep medicine",
                    rank: 58,
                  },
                  {
                    id: "Sports and exercise medicine",
                    name: "Sports and exercise medicine",
                    rank: 59,
                  },
                  {
                    id: "Stomatology",
                    name: "Stomatology",
                    rank: 60,
                  },
                  {
                    id: "Therapy",
                    name: "Therapy",
                    rank: 61,
                  },
                  {
                    id: "Thoracic surgery",
                    name: "Thoracic surgery",
                    rank: 62,
                  },
                  {
                    id: "Tropical medicine",
                    name: "Tropical medicine",
                    rank: 63,
                  },
                  {
                    id: "Urology",
                    name: "Urology",
                    rank: 64,
                  },
                  {
                    id: "Vascular surgery",
                    name: "Vascular surgery",
                    rank: 65,
                  },
                  {
                    id: "Venereology",
                    name: "Venereology",
                    rank: 66,
                  },
                ].map(specialty => (
                  <option key={specialty.name} value={specialty.id}>
                    {specialty.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.specialty}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={4} md={12}>
            <FormGroup>
              <Label for="role">Your role</Label>
              <Input
                value={form.role}
                onChange={handleChange}
                type="select"
                name="role"
                id="role"
                invalid={!!formErrors.role}
              >
                {[
                  {
                    id: "",
                    name: "",
                    rank: -1,
                  },
                  {
                    id: "Clinical",
                    name: "Clinical",
                    rank: 0,
                  },
                  {
                    id: "Administrative",
                    name: "Administrative (e.g. practice manager or secretary)",
                    rank: 1,
                  },
                  {
                    id: "Billing/Financial",
                    name: "Billing/Financial",
                    rank: 2,
                  },
                  {
                    id: "Patient",
                    name: "Patient",
                    rank: 3,
                  },
                  {
                    id: "Other",
                    name: "Other",
                    rank: 4,
                  },
                ].map(option => (
                  <option key={option.name} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.role}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={4} md={12}>
            <FormGroup>
              <Label for="practice_size">Number of clinicians</Label>
              <Input
                value={form.practice_size}
                onChange={handleChange}
                type="select"
                name="practice_size"
                id="practice_size"
                invalid={!!formErrors.practice_size}
              >
                {[
                  { id: "", name: "", rank: -1 },
                  { id: "1", name: "1", rank: 0 },
                  { id: "2", name: "2", rank: 1 },
                  { id: "3", name: "3", rank: 2 },
                  { id: "4", name: "4", rank: 3 },
                  { id: "5", name: "5", rank: 4 },
                  { id: "6", name: "6", rank: 5 },
                  { id: "7", name: "7", rank: 6 },
                  { id: "8", name: "8", rank: 7 },
                  { id: "9", name: "9", rank: 8 },
                  { id: "10+", name: "10+", rank: 9 },
                ].map(option => (
                  <option key={option.name} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.practice_size}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={4} md={12}>
            <FormGroup>
              <Label for="referral_code">Referral code (optional)</Label>
              <Input
                value={form.referral_code}
                onChange={handleChange}
                type="text"
                name="referral_code"
                id="referral_code"
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={12}>
            <FormGroup>
              <Label for="email">Email address</Label>
              <Input
                value={form.email}
                onChange={handleChange}
                invalid={!!formErrors.email}
                type="text"
                name="email"
                id="email"
              />
              <FormFeedback>{formErrors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} md={12}>
            <FormGroup>
              <Label for="phone">Phone number</Label>
              <Input
                value={form.phone}
                onChange={handleChange}
                invalid={!!formErrors.phone}
                type="tel"
                name="phone"
                id="phone"
              />
              <FormFeedback>{formErrors.phone}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={12} md={12}>
            <FormGroup>
              <Label for="content">
                Details of your private practice, requirements and other
                information
              </Label>
              <Input
                value={form.content}
                onChange={handleChange}
                invalid={!!formErrors.content}
                type="textarea"
                rows={4}
                name="content"
                id="content"
              />
              <FormFeedback>{formErrors.content}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <div style={{ textAlign: "center" }}>
          <Button
            color="secondary"
            onClick={() => {
              handleSubmit()
            }}
            style={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GetStartedForm
