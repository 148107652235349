import React from "react"
import Header from "../components/Header"

class HeaderContainer extends React.Component {
  state = {
    isMobileNavOpen: false,
  }

  toggleMobileNav = () => {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    })
  }

  render() {
    return (
      <Header
        toggleMobileNav={this.toggleMobileNav}
        isMobileNavOpen={this.state.isMobileNavOpen}
      />
    )
  }
}

export default HeaderContainer
