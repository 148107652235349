import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import FooterCTA from "src/components/FooterCTA"

import CarebitBadge from "src/assets/img/carebit-badge.png"

const Footer = () => (
  <Fragment>
    <FooterCTA />

    <footer>
      <Container className="Footer">
        <Row>
          <Col lg={3} md={4} className="Footer-column">
            <h5 className="subtext">Features</h5>
            <ul className="u-listStyleNone">
              <li>
                <Link to="/features/automation">Automation</Link>
              </li>
              <li>
                <Link to="/features/compose-letters">Compose letters</Link>
              </li>
              <li>
                <Link to="/features/patient-portal">Patient portal</Link>
              </li>
              <li>
                <Link to="/features/revenue-enhancement">
                  Revenue enhancement
                </Link>
              </li>
              <li>
                <Link to="/features/online-payment">Online payment</Link>
              </li>
              <li>
                <Link to="/features/practice-management">
                  Practice management
                </Link>
              </li>
              <li>
                <Link to="/features/online-booking">Online booking</Link>
              </li>
            </ul>
          </Col>

          <Col lg={3} md={4} className="Footer-column">
            <h5 className="subtext">Case studies</h5>
            <ul className="u-listStyleNone">
              <li>
                <Link to="/case-studies/santis-automating-secretarial-tasks">
                  Automating secretarial tasks
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={3} md={4} className="Footer-column">
            <h5 className="subtext">Company</h5>
            <ul className="u-listStyleNone">
              <li>
                <Link to="/privacy-policy">Privacy policy</Link>
              </li>
              <li>
                <Link to="/terms-of-service">Terms of service</Link>
              </li>
              <li>
                <Link to="/security">Security</Link>
              </li>
            </ul>
          </Col>

          <Col lg={3} md={4} className="Footer-column">
            <h5 className="subtext">Information</h5>
            <ul className="u-listStyleNone">
              <li>
                <Link to="/migrating-to-carebit">Migrating to Carebit</Link>
              </li>
              <li>
                <Link to="/for-patients">For patients</Link>
              </li>
              <li>
                <Link to="/for-clinics">For clinics</Link>
              </li>
            </ul>
          </Col>

          <Col lg={3} md={4} className="Footer-column">
            <h5 className="subtext">Contact us</h5>
            <ul className="u-listStyleNone">
              <li>
                <a href="tel:+442030263570">+44 (0)203 026 3570</a>
              </li>
              <li>
                <a href="mailto:info@carebit.co">info@carebit.co</a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 4, offset: 4 }}
            style={{
              marginTop: "40px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <img
                src={CarebitBadge}
                alt="Carebit logo"
                style={{
                  width: "30px",
                  display: "inline-block",
                  marginRight: "15px",
                  position: "relative",
                  top: "2px",
                }}
              />
            </Link>
            <ul
              className="u-listStyleNone"
              style={{
                display: "inline-block",
                textAlign: "left",
                marginBottom: "0",
              }}
            >
              <li style={{ fontSize: "0.875rem" }}>
                &copy; Carebit Health Limited {new Date().getFullYear()}.
              </li>
              <li style={{ fontSize: "0.875rem" }}>
                Registered in the UK under company number 09715186.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  </Fragment>
)

export default Footer
