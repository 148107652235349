import React from "react"
import { Link } from "gatsby"

import {
  Navbar,
  Nav,
  NavItem,
  Collapse,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"

import CarebitLogo from "../assets/img/carebit-logo.png"

const Header = ({ toggleMobileNav, isMobileNavOpen }) => {
  return (
    <div className="Header">
      <Navbar expand="md" light>
        <Link to="/">
          <img
            className="navbar-logo img-responsive"
            src={CarebitLogo}
            alt="Carebit logo"
          />
        </Link>
        <NavbarToggler onClick={toggleMobileNav} />
        <Collapse isOpen={isMobileNavOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>Features</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/features/automation">Automation</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/billing">Billing</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/compose-letters">Compose letters</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/online-booking">Online booking</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/online-payment">Online payment</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/patient-portal">Patient portal</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/practice-management">
                    Practice management
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/features/revenue-enhancement">
                    Revenue enhancement
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>Case studies</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/case-studies/santis-automating-secretarial-tasks">
                    Automating secretarial tasks
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/migrating-to-carebit">
                Migrating to Carebit
              </Link>
            </NavItem>
            <NavItem>
              <NavLink href="#get-started">
                <Button color="secondary" size="md">
                  Get started &rarr;
                </Button>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Header
