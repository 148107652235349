import React from "react"
import { Container, Row, Col } from "reactstrap"

import GetStartedForm from "src/components/forms/GetStartedForm"

const FooterCTA = () => (
  <div className="FooterCTA">
    <section id="get-started">
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <Row>
              <Col md={12}>
                <GetStartedForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
)

export default FooterCTA
